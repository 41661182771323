<template lang="pug">
.usersPage
    v-row
        v-col
            v-card(light, flat)
                v-card-title
                    span Portal Users
                    v-icon(small, title='View and edit portal users') info
                v-card-subtitle.d-flex
                    v-text-field(v-model='searchText', prepend-inner-icon='search', label='Search', single-line, hide-details, clearable, clear-icon='clear', color='brand', outlined, dense, light, solo, rounded, flat)

    v-row
        v-col
            v-data-table(:headers='columns', :items='users', dense, show-expand, @click:row='userRowClicked', fixed-header, light, disable-pagination, hide-default-footer, single-expand, :search='searchText', height='80vh')
                template(v-slot:expanded-item='{headers, item}')
                    td(:colspan='headers.length')
                        v-row
                            v-col.py-6
                                UserEditor(:username='item.username', :editMode='editMode', @userUpdated='userUpdated')
                        v-row
                            v-col
                                .text-h6 Linked to the following customers:
                        v-row
                            v-col
                                span {{ getCustomerList(item) }}
                        v-row
                            v-col.pb-6
                                v-btn.mr-2(small, @click='editMode=!editMode', text, :disabled='editMode', outlined) Edit User
                                v-btn.white--text(color='red', small, @click='showDeleteDialog(item)') Delete User
                template(v-slot:item.customers='{item}')
                    span {{ getCustomerList(item) }}

    v-dialog(v-model='deleteDialog', v-if='deleteDialog', max-width='500px')
        v-card(light)
            v-card-title Delete User
            v-card-text
                p Are you sure you want to delete this user? This action cannot be undone.
                p They are currently associated with the following customers:
                p {{ getCustomerList(userToDelete) }}
            v-card-actions
                v-spacer
                v-btn(text, @click='deleteDialog=false') Cancel
                v-btn.white--text(color='red', @click='deleteUser') Delete

    v-overlay(absolute, :value='busy')
        .text-h6 Please Wait...
        v-progress-linear(indeterminate)

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script>
import _ from "lodash";
import dti from "dti";
import UserEditor from '@/components/portal/usereditor.vue';

export default {
    components: {
        UserEditor
    },
    data () {
        return {
            dti,
            accessKey: 'devopsusers',
            message: '',
            searchText: '',
            users: [],
            editMode: false,
            columns: [
                {
                    text: 'First Name',
                    value: 'firstName'
                },
                {
                    text: 'Last Name',
                    value: 'lastName'
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'Customer(s)',
                    value: 'customers'
                }
            ],
            deleteDialog: false,
            userToDelete: null
        };
    },
    methods: {
        async getData () {
            this.socketEmit('getAllUsers', null, (results) => {
                this.users = results;
            });
        },
        async deleteUser () {
            const result = await this.sendCommand({
                action: 'deletePortalUser',
                parameters: this.userToDelete.id
            });
            if (result.err) {
                this.message = 'Error deleting user';
            }
            this.deleteDialog = false;
            this.userToDelete = null;
            this.getData();
        },
        async resetPassword ({userId, password}) {
            const result = await this.sendCommand({
                action: 'resetUserPassword',
                parameters: {
                    userId,
                    password
                }
            });
            if (result.err) {
                this.message = 'Error resetting password';
            }
            this.getData();
        },
        /**
         *
         * @param {object} user
         * @returns {string} list of customers
         */
        getCustomerList (user) {
            const customers = user.customers.map(customer => customer.name);
            return customers.join(', ');
        },
        /**
         * Opens the delete dialog and stages the user for deletion
         *
         * @param {object} user
         */
        showDeleteDialog (user) {
            this.userToDelete = user;
            this.deleteDialog = true;
        },
        /**
         * Turns off edit mode after a user has been saved.
         */
        userUpdated () {
            this.editMode = false;
        },
        /**
         * Expands the row and turns off edit mode
         *
         * @param {object} user
         * @param {*} slot
         */
        userRowClicked (user, slot) {
            slot.expand(!slot.isExpanded); // expand the row
            this.editMode = false;
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== "";
            },
            set (val) {
                if (val === false) {
                    this.message = "";
                }
            },
        },
    },
    watch: {},
    mounted () {
        this.getData();
        window.customerusersvm = this;
    },
};
</script>

<style lang="scss" scoped></style>

<style lang="scss"></style>
